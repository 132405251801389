import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-privacy',
  templateUrl: './supplier-privacy.component.html',
  styleUrls: ['./supplier-privacy.component.scss']
})
export class SupplierPrivacyComponent {

}
