import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, UrlTree } from "@angular/router";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    oldValue = 0
    newValue = 0

    constructor(public location: Location, private element : ElementRef, public router: Router) {
        this.sidebarVisible = true;
    }
    
  public isRouteActive(route: string | UrlTree) {
    return this.router.isActive(route,false)
}

@HostListener('window:scroll', ['$event'])
onScroll(event) {
 
  let newValue = window.pageYOffset;
  if (this.oldValue < newValue) {
    this.sidebarVisible = false;
  } else if (this.oldValue > newValue) {
    this.sidebarVisible = true;
  }
  this.oldValue = newValue;
}

setStyle(isSelected:boolean): string {
  if(isSelected){
    return 'letraSombreada';
  } else {
    return 'letra';
    }
}

backStyle(isSelected:boolean,color?:string): string {
  if(isSelected){
    return color.toString();
  } else {
    return;
    }
}

ngOnInit() {
  const navbar: HTMLElement = this.element.nativeElement;
  this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
  }

sidebarOpen() {
  const toggleButton = this.toggleButton;
  const html = document.getElementsByTagName('html')[0];
  setTimeout(function(){
  toggleButton.classList.add('toggled');
  }, 500);
  html.classList.add('nav-open');

  this.sidebarVisible = true;
  };
    
scroll(id) {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  el.scrollIntoView();
}

mobileView(){
  if(window.innerWidth < 750) {
    return true;
  }
  return false;
}
  
ipadView(){
  if (window.innerWidth < 1040 && window.innerWidth > 750) {
    return true;
  }
  return false;
}
   
}
