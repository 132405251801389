import { Component, OnInit, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CrudService } from 'app/services/crud.service';
import { ValidatorService } from 'app/shared/validator/validator.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})

export class PresentationComponent implements OnInit, OnDestroy {

  constructor(public el: ElementRef,
              private fb: UntypedFormBuilder,
              private validatorService: ValidatorService,
              private crudService: CrudService) { }

generalForm: UntypedFormGroup = this.fb.group({
    surName:['', [Validators.required, Validators.pattern(this.validatorService.name), Validators.maxLength(50)]],
    email:['', [Validators.required, Validators.pattern(this.validatorService.emailPattern), Validators.maxLength(80)]],
    inquiry:['', [Validators.required, Validators.minLength(30), Validators.maxLength(1000)]],
    privacy: ['', Validators.requiredTrue]
})


get emailErrorMsg():string{
  const errors = this.generalForm.get('email').errors
    if (errors.required) {
      return 'Es necesario que ingreses tu correo electrónico'
    } else if (errors.pattern) {
        return 'El formato del correo electrónico que ingresaste es incorrecto'
      }
    return ''
}

filedIsValid(field:string){
  return  this.generalForm.get(field).invalid
          && this.generalForm.get(field).touched
}

setStyles(tag) {
  return {
     ['styleMobile'] : {'text-align' : 'center', 'font-size' : 'large', 'margin-left' : '10px', 'margin-right' : '10px', 'margin-top' : '15px'},
     ['styleWeb'] : {'text-align' : 'center', 'font-size' : 'x-large', 'margin-left' : '20px', 'margin-right' : '20px'},
 }[tag];
}

sendForm(){
  this.generalForm.markAllAsTouched();
  this.crudService.AddGeneralInquiry(this.generalForm.value).subscribe(success => {
    if (success === true) {
      Swal.fire('¡Mensaje enviado!', 'Muchas gracias por tu mensaje, te responderemos a la brevedad posible', 'success');
    } else {
      Swal.fire('Error', success, 'error');
    }
  });
}

ngOnInit() {
  var body = document.getElementsByTagName('body')[0];
  body.classList.add('presentation-page');
  body.classList.add('loading');
  var navbar = document.getElementsByTagName('nav')[0];
  navbar.classList.add('navbar-transparent');
  this.generalForm.reset({
    surName:'',
    email:'',
    inquiry:'',
    privacy:false
  })
}
  
ngOnDestroy() {
  var body = document.getElementsByTagName('body')[0];
  body.classList.remove('presentation-page');
  body.classList.remove('loading');
  var navbar = document.getElementsByTagName('nav')[0];
  navbar.classList.remove('navbar-transparent');
}

mobileView() {
  if(window.innerWidth < 750) {
    return true;
  }
  return false;
}

ipadView() {
  if (window.innerWidth < 1040 && window.innerWidth > 750) {
    return true;
  }
  return false;  
}

}
