import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { TermsConditionsComponent } from './terms-conditions.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule
    ],
    declarations: [
        TermsConditionsComponent,
      
    ],
    exports:[ TermsConditionsComponent ]
})
export class TermsConditionsModule { }
