import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  public name: string = '([a-zA-ZÀ-ÿ\u00f1\u00d1 ]+) ([a-zA-ZÀ-ÿ\u00f1\u00d1 ]+)'
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
  public mobilPattern: string = '[0-9]{10}$'

  constructor() { }
}
