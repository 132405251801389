import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorService } from 'app/shared/validator/validator.service';
import { DataService } from '../services/data.service';
import { CrudService } from '../services/crud.service';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls:['./supplier.component.scss'],
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class SupplierComponent implements OnInit, OnDestroy {

    initialValues: any;
    states: any;
    municipalities: any;
    selectedState: any = {
        id: 0, name: ''
    };

    constructor(private dataService: DataService,
                private fb: UntypedFormBuilder,
                private validatorService: ValidatorService,
                private crudService: CrudService) { }

supplierForm: UntypedFormGroup = this.fb.group({
    place:['', [Validators.required]],
    municipality:['', [Validators.required]],
    surName:['', [Validators.required, Validators.pattern(this.validatorService.name), Validators.maxLength(50)]],
    email:['', [Validators.required, Validators.pattern(this.validatorService.emailPattern), Validators.maxLength(80)]],
    mobileNumber:['', [Validators.required, Validators.pattern(this.validatorService.mobilPattern)]],
    builder:['false'],
    carpenter:['false'],
    electrician:['false'],
    blacksmith:['false'],
    flooring:['false'],
    gardener:['false'],
    cleaning:['false'],
    paintbrush:['false'],
    plumber:['false'],
    plasterer:['false'],
    others:['', [Validators.minLength(4), Validators.maxLength(50)]],
    privacy: ['', Validators.requiredTrue]
})

get emailErrorMsg():string{
    const errors = this.supplierForm.get('email').errors
    if (errors.required) {
      return 'Es necesario que ingreses tu correo electrónico'
    } else if (errors.pattern) {
        return 'El formato del correo electrónico que ingresaste es incorrecto'
      }
    return ''
}

get mobilErrorMsg():string{
    const errors = this.supplierForm.get('mobileNumber').errors
    if (errors.required) {
      return 'Tu número de celular es requerido'
    } else if (errors.pattern) {
        return 'Porfavor, ingresa tu número celular de 10 digitos sin espacios'
      }
    return ''
}

filedIsValid(field:string){
    return  this.supplierForm.get(field).invalid
            && this.supplierForm.get(field).touched
}

sendForm():any {
    this.supplierForm.markAllAsTouched();
    this.crudService.AddSupplierContact(this.supplierForm.value).subscribe(success => {
        if (success === true) {
            Swal.fire('¡Solicitud Enviada!', 'Listo, te contactaremos tan pronto como la aplicación este lista para que la descargues', 'success');
            this.supplierForm.reset(this.initialValues);
            this.changeClass();
        } else {
            Swal.fire('Error', success, 'error');
            this.supplierForm.reset(this.initialValues);
            this.changeClass();
        }
      });
}

changeClass() {
    var clase = Array.from(document.getElementsByClassName('btn btn-outline-primary colorW'));
    console.log(clase)
    for (let i = 0; i <= clase.length; i++) {
      console.log(clase.length)
      console.log(i)
      clase[i].className='btn btn-outline-primary colorG';
    }
  }

changeColor(id: string) {
    let propiety= document.getElementById(id);

    if (propiety.className == 'btn btn-outline-primary colorG') {
       propiety.className = 'btn btn-outline-primary colorW'
    } else {propiety.className ='btn btn-outline-primary colorG'}
}

ngOnInit() {
    this.initialValues = this.supplierForm.value;
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
    let input_group = document.getElementsByClassName('input-group');
    for (let i = 0; i < input_group.length; i++) {
        input_group[i].children[0].addEventListener('focus', function (){
        input_group[i].classList.add('input-group-focus');
        });
        input_group[i].children[0].addEventListener('blur', function (){
        input_group[i].classList.remove('input-group-focus');
        });
    }
    this.showAll();
    this.onSelect(this.selectedState.id);
}

ngOnDestroy() {
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
}

setStyles(tag) {
    return {
       ['styleMobile'] : {'text-align' : 'center', 'font-size' : 'large', 'margin-left' : '10px', 'margin-right' : '10px', 'margin-top' : '15px'},
       ['styleWeb'] : {'text-align' : 'center', 'font-size' : 'x-large', 'margin-left' : '20px', 'margin-right' : '20px'},
   }[tag];
}

mobileView(){
    if(window.innerWidth < 750){
      return true;
    }
    return false;
  }

ipadView(){
    if (window.innerWidth < 1040 && window.innerWidth > 750){
        return true;
    }
    return false;
  
  }

showAll() {
    this.dataService.getAll().subscribe(
        (data:any) => {
            this.states = data
        }
    )
}

onSelect(state_id: any) {
    this.dataService.getAll().subscribe((res: any) => {
        this.municipalities = res['municipalities'].filter(
            (res:any) => res.state_id == state_id!.value)
    })
}

}
