import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { SupplierContact, SupplierResponse } from './supplierInquiry'
import { ClientContact, ClientResponse } from './clientInquiry';
import { GeneralInquiry, GeneralResponse } from './generalInquiry';

@Injectable({
  providedIn: 'root'
})

export class CrudService {

  constructor(private clientHttp:HttpClient) { }

  generalContactAPI: string = 'https://us-central1-skil-o-mobile.cloudfunctions.net/generalContactFunctions/generalForm';
  supplierContactAPI: string = 'https://us-central1-skil-o-mobile.cloudfunctions.net/supplierContactFunctions/supplierContactForm';
  clientContactAPI: string = 'https://us-central1-skil-o-mobile.cloudfunctions.net/clientContactFunctions/clientContactForm';

  AddGeneralInquiry(generalInquiry:GeneralInquiry):Observable<any>{
    return this.clientHttp.post<GeneralResponse>(this.generalContactAPI, generalInquiry)
    .pipe(
      map(resp => resp.data),
      catchError(err => of(err.error.msg))
    )
  }

  AddSupplierContact(supplierContact:SupplierContact):Observable<any>{
    console.log(supplierContact)
    return this.clientHttp.post<SupplierResponse>(this.supplierContactAPI, supplierContact)
    .pipe(  
      map(resp => resp.data),
      catchError(err => of(err.error.msg))
    )
  }

  AddClientContact(clientContact:ClientContact):Observable<any>{
    return this.clientHttp.post<ClientResponse>(this.clientContactAPI, clientContact)
    .pipe(
      map(resp => resp.data),
      catchError(err => of(err.error.msg))
    )
  }
}
