import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PresentationComponent } from './presentation.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,  
        ReactiveFormsModule,
        HttpClientModule
    ],
    declarations: [ PresentationComponent ],
    exports: [ PresentationComponent ],
    providers: []
})

export class PresentationModule { }


