import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-privacy',
  templateUrl: './customer-privacy.component.html',
  styleUrls: ['./customer-privacy.component.scss']
})
export class CustomerPrivacyComponent {

}
