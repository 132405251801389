import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { PresentationModule } from './presentation/presentation.module';
import { ClientsModule } from './clients/clients.module';
import { SupplierModule } from './supplier/supplier.module';
import { SupplierPrivacyModule } from './supplier-privacy/supplier-privacy.module';
import { CustomerPrivacyModule } from './customer-privacy/customer-privacy.module';
import { TermsConditionsModule } from './terms-conditions/terms-conditions.module';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        PresentationModule,
        SupplierModule,
        ClientsModule,
        SupplierPrivacyModule,
        CustomerPrivacyModule,
        TermsConditionsModule,
        HttpClientModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
