// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a {
  font-size: 17px !important;
}

.divCenter {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}

.imageCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.boton {
  background-color: #2C87CA !important;
  border: #2C87CA !important;
}

.letra {
  font-size: 17px;
  font-weight: 600;
  color: rgb(0, 113, 188);
}

.letraSombreada {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  border-radius: 15px;
  background-color: #2792CC;
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.dropdown-toggle::after {
  content: none !important;
}

@media screen and (max-width: 991px) {
  .dropdown.show .dropdown-menu {
    position: absolute !important;
    float: left !important;
    margin-top: 8% !important;
  }
}
@media (max-width: 767px) {
  .btn.dropdown-toggle {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.color {
  background-image: linear-gradient(#12142D, #004F82, #007795, #00A8B3, #00E2D7);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AAEA;EACI,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AAEA;EACI,oCAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI;IACI,6BAAA;IACA,sBAAA;IACA,yBAAA;EACN;AACF;AAEA;EACI;IACI,gBAAA;IACA,aAAA;EAAN;AACF;AAGA;EACI,8EAAA;AADJ","sourcesContent":[".a {\n    font-size: 17px !important;\n}\n\n.divCenter {\n    display: flex;\n    position: relative;\n    overflow: hidden;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.imageCenter {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: auto;\n    margin-bottom: auto;\n    width: 100%;\n}\n\n.boton {\n    background-color: #2C87CA !important;\n    border: #2C87CA !important;\n}\n\n.letra {\n    font-size: 17px;\n    font-weight: 600;\n    color: rgb(0, 113, 188)\n}\n\n.letraSombreada {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: auto;\n    margin-bottom: auto;\n    width: 100%;\n    border-radius: 15px;\n    background-color: #2792CC;\n    font-size: 17px;\n    font-weight: 600;\n    color: white;\n}\n\n.dropdown-toggle::after {\n    content: none !important;\n}\n\n@media screen and (max-width: 991px) {\n    .dropdown.show .dropdown-menu {\n        position: absolute !important;\n        float: left !important;\n        margin-top: 8% !important;\n    }\n}\n\n@media (max-width: 767px) {\n    .btn.dropdown-toggle {\n        margin-bottom: 0;\n        margin-top: 0;\n    }\n}\n\n.color {\n    background-image: linear-gradient( #12142D, #004F82, #007795, #00A8B3, #00E2D7);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
