// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    ngb-progressbar {
        margin-top: 5rem;
    }
    `, "",{"version":3,"sources":["webpack://./src/app/supplier/supplier.component.ts"],"names":[],"mappings":";IACI;QACI,gBAAgB;IACpB","sourcesContent":["\n    ngb-progressbar {\n        margin-top: 5rem;\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
